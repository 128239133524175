@if (mode == 'add') {
<section class="header container-fluid">
    <div class="row d-flex align-items-center py" #header>
        <div class="header_logo_flx">
            <div class="application-logo cursor-pointer">
                <img src="assets/images/common/logo.svg" class="" alt="logo" />
            </div>

            <div class="">
                <div class="mb-0 fw-300 d_flx_rwanda cursor-pointer">
                    <span class="username header-logo">
                        <img *ngIf="countryLogoAttachment; else fallbackLogo" [src]="countryLogoAttachment"
                            class="logo-image" alt="logo" />
                        <ng-template #fallbackLogo>
                            <img src="assets/images/common/rwanda_logo.svg" class="logo-image" alt="default logo" />
                        </ng-template>
                    </span>
                    <span class="rwanda_img">
                        {{
                        countryLogoName ||

                        staticText?.common?.company_secondary_name
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
}


<div class=" onboarding-container border_top_onboard padding_setup  ">
    <!-- Header section .................-->
    <header class="d-flex align-items-center onboarding-header container-xl px-0">
        <div class="view_header d-flex">
            <div class="d-flex  onboarding-header-title">
                <div>{{staticText?.setup_configuration?.setup_subheading}}</div>
            </div>
        </div>
    </header>


    <section class="scp-onboarding-screen container-xl px-0 ">
        <mat-card class="card-default-style card_style p-0">

            <mat-horizontal-stepper class="label_step" #stepperValidation labelPosition="bottom"
                [selectedIndex]="selectedIndex" [orientation]="(stepperOrientation | async)!" [linear]="true"
                (selectionChange)="onSteperChange($event)">
                <ng-template matStepperIcon="edit">
                    <i class="fa fa-check custom-fs"></i>
                </ng-template>
                <mat-step [label]="staticText?.setup_configuration?.branding_setup ?? 'Branding Setup'"
                    [completed]="isValidBrandInfo">
                    <div class=" fs-24  business-title-card">
                        <span>{{ staticText?.setup_configuration?.branding_setup}}</span>
                    </div>

                    <div class="custom-style-onboarding mtrem-2">
                        @if(brandConfig && configData) {
                        <app-dynamic-form [sections]="brandConfig" [processAttachment]="true" [refID]="id"
                            #brandConfigRef [apiData]="configData" [disableForm]="false" [mode]="mode">
                        </app-dynamic-form>
                        }
                    </div>

                </mat-step>
                <mat-step [label]="staticText?.setup_configuration?.administrative_setup ?? 'Administrative Setup'"
                    [completed]="isValidGeographicalInfo">
                    <div class=" fs-24  business-title-card">
                        <span>{{ staticText?.setup_configuration?.administrative_setup}}</span>
                    </div>

                    <div class="custom-style-onboarding geo_config">
                        @if(geoConfig && configData) {
                        <app-dynamic-form [sections]="geoConfig" [refID]="id" #geoConfigRef [apiData]="configData"
                            [disableForm]="false" [mode]="mode">
                        </app-dynamic-form>
                        }
                    </div>
                </mat-step>
                <mat-step [label]="staticText?.setup_configuration?.localization_setup ?? 'Localization Setup'"
                    [completed]="isValidLocalizationInfo">
                    <div class=" fs-24  business-title-card">
                        <span>{{ staticText?.setup_configuration?.localization_setup}}</span>
                    </div>


                    <div class="custom-style-onboarding">
                        @if(localizationConfig && configData) {
                        <app-dynamic-form [sections]="localizationConfig" [refID]="id" #localizationConfigRef
                            [apiData]="configData" [disableForm]="false" [processAttachment]="true" [mode]="mode">
                        </app-dynamic-form>
                        }
                    </div>
                </mat-step>
                <mat-step [label]="staticText?.setup_configuration?.transaction_setup ?? 'Transaction Setup'"
                    [completed]="isValidAccountingInfo">
                    <div class=" fs-24  business-title-card">
                        <span>{{ staticText?.setup_configuration?.transaction_setup}}</span>
                    </div>


                    <div class="custom-style-onboarding config_acnt">
                        @if(accountingConfig && configData) {
                        <app-dynamic-form [sections]="accountingConfig" [refID]="id" #accountingConfigRef
                            [apiData]="configData" [disableForm]="false" [mode]="mode">
                        </app-dynamic-form>
                        }
                    </div>
                </mat-step>
                <mat-step class="kyc_config"
                    [label]="staticText?.setup_configuration?.kyc_and_iam_setup ?? 'KYC & IAM Setup'"
                    [completed]="isValidKYCInfo">
                    <div class=" fs-24  business-title-card">
                        <span>{{ staticText?.setup_configuration?.kyc_and_iam_setup}}</span>
                    </div>
                    @if(kycConfig && configData) {
                    <app-dynamic-form [sections]="kycConfig" [refID]="id" #kycConfigRef [apiData]="configData"
                        [disableForm]="false" [mode]="mode">
                    </app-dynamic-form>
                    }
                </mat-step>


            </mat-horizontal-stepper>

            @if (enableLoader) {
            <!-- Loader start ............ -->
            <div class="spinner_center">
                <div class="overlay">

                    <div class="loader loader_big"></div>
                </div>
            </div>
            <!-- ............ Loader End  -->
            }

        </mat-card>
    </section>
</div>


<!-- Footer section .................-->

<div class="foot_height_cmn"></div>


<div class=" guest-footer py-2">
    <div class="container d-flex submit_flx btn_justify btn_two_sm px-0">


        <div class="btn-align">
            <button class="btn_default_user" mat-stroked-button (click)="close()">
                <span>{{staticText?.common?.cancel_button}}</span>
            </button>
        </div>

        <ng-container>
            <div class="action-btns-2 justify-content-end align-items-center text-capitalize cursor-pointer">
                <div class="py-2 d-flex flex_col_user" *ngIf="mode == 'edit'">
                    <button class="btn_login_user" mat-raised-button *ngIf="currentStepperDetails.prevBtn.title != ''"
                        class="btn_login_user user_btn_border" (click)="prevStep()">
                        <div>
                            <i class="fa fa-arrow-left mx-2"></i>
                            <span [innerHtml]="currentStepperDetails.prevBtn.title" class=""></span>
                        </div>
                    </button>

                    <button class="btn_login_user" *ngIf="currentStepperDetails.nextBtn.title != ''" mat-raised-button
                        (click)="saveCurrentStep('next')">
                        <div>
                            <span [innerHtml]="currentStepperDetails.nextBtn.title" class="text-light"></span>

                            <img class="mx-2" src="../../../../assets/images/common/icons_usp/right.svg" alt="right" />
                        </div>
                    </button>
                </div>
                <div class="py-2 d-flex flex_col_user" *ngIf="mode == 'view' && configData">
                    <button class="btn_login_user" mat-stroked-button (click)="gotoEdit()">
                        <span><i class="fa fa-edit"></i></span>
                        <span>&nbsp;{{staticText?.common?.edit}}</span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<footer>
    <span class="text-sm text-black"> {{ copyRight }} </span>
</footer>


<!--................. Footer section -->